import { IS_BROWSER } from '$fresh/runtime.ts';
import Button, { ButtonProps } from 'components/button/Button.tsx';
import { useRef } from 'preact/hooks';
import { ForwardedRef, forwardRef } from 'react-dom';
import HashCode from 'hash/HashCode.ts';

const HydroButton = forwardRef(function (
    props: ButtonProps,
    ref?: ForwardedRef<HTMLDivElement>,
) {
    if (!IS_BROWSER) return <></>;

    if (!props.id) {
        props.id = 'HydroButton_' + HashCode.bind(props)().toString() + '_' +
            Math.round(Math.random() * (10 ** 12)).toString();
    }

    ref ??= useRef<HTMLDivElement>(null);

    return (
        <Button
            {...props}
            ref={ref}
            disabled={!IS_BROWSER || props.disabled}
        />
    );
});

export default HydroButton;
